import axios from "axios"
import qs from 'qs'
import { Toast } from 'vant';
import router from '../router'
import errorCode from '@/utils/errorCode'
import { LOGIN_TOKEN } from '@/store/mutation-types'
import storage from 'store'

const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  console.log('err' + error)
    let { message } = error
    if (message === 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    Toast.fail({
      message: message,
      duration: 5 * 1000
    })
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get(LOGIN_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // 处理params参数
  if (config.params) {
    const url = config.url + '?' + qs.stringify(config.params, { indices: false })
    config.params = {}
    config.url = url
  }
  return config
}, errorHandler)

request.interceptors.response.use((res) => {
  // 请求rul
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  if (code === 401) {
    Toast({
      message: '登录状态已过期，请重新登录',
      duration: '1500',
      onClose() {
        router.push('/login')
      }
    });
  } else if (code === 500) {
    Toast(msg)
  } else if (code !== 200) {
    Toast(msg)
  } else {
    //isOriginal 是否返回原始值。有时需要使用到后端接口返回的与data同级别其他的值，所以是否返回原始值可以在请求时设置
    if(res.config.isOriginal){
      return res.data
    }else{
      return res.data.data
    }
  // return Promise.resolve(res.data.data)

  }
  return Promise.reject(msg)
}, errorHandler)

export default request