import request from '@/utils/request'
import router from '@/router/index.js'
import { Toast } from 'vant';
import {
  ElMessageBox
} from 'element-plus'

//queryType: 0=进入园区、1=离开园区
var queryType = 0


/**
 *新的识别结果，根据结果跳转不同页面
 * @result 预约id
 * */
export default function getResult(result) {
  console.log(result)
  return new Promise((resolve, reject) => {
    request({
      url: "/security/type",
      method: "GET",
      params: {
        code: result,
        entranceId:this.$store.state.entranceId
      },
      isOriginal:true // 常驻人口，需要弹框显示msg，所以显示原始值
    }).then((res) => {
      //特殊情况，常驻人口通过此接口扫码入场或者离场
      if(res.data===null){
        Toast(res.msg)
        reject('常驻人口')
      }else{
        res=res.data
      }

      //  type: 0=个人、1=5人,
      //  queryType: 0=进入园区、1=离开园区
      if (res.type == 0) { //单人
        queryType=res.queryType
        requestTo(result).catch(()=>{
          reject()
        })
      } else if (res.type == 1) { //多人
        ElMessageBox.confirm(
            '请选择入厂或者离厂', {
              confirmButtonText: '入厂',
              cancelButtonText: '离厂',
              type: 'success',
              buttonSize: 'large',
              customClass: 'ElMessageBox',
              distinguishCancelAndClose: true,
              beforeClose: function (action, a, done) {
                reject(action)
                done()
              }
            }
          )
          .then(() => {//入厂
            queryType=0
            requestTo(result)
          }).catch((action) => {
            //action==cancel 点击取消，action==close 点击关闭
            if (action == 'cancel') {//离厂
              queryType=1
              requestTo(result)
            }

          })
      }
    })

  });


}

function requestTo(result){
  return new Promise((resolve, reject) => {
    request({
      url: "/security/query",
      method: "GET",
      params: {
        code: result,
        queryType:queryType
      },
    }).then((res) => {
      resolve()
      //避免重复扫码的情况，将扫码得到的id存储到数组
      let ids=localStorage.getItem('ids')
      ids=ids ? JSON.parse(ids) : []
      ids.push(result)
      localStorage.setItem('ids',JSON.stringify(ids))
  
      //0 个人预约， 1 多人预约
      if (res.type == 0) {
        //保安选择入场或者离场
        if(queryType == 0){
          router.replace({path:'/enter/personal',query:{code:result}})
        }else if(queryType == 1){
          router.replace({path:'/leave/personal',query:{code:result}})
        }
      }else if(res.type == 1){
        //待处理状态(0=待入厂放行,1=待离厂放行)
        //保安选择入场或者离场
        if(queryType==0){
          router.replace({path:'/enter/multiple',query:{code:result}})
        }else if(queryType==1){
          router.replace({path:'/leave/multiple',query:{code:result}})
        }
      }
  
    }).catch(err=>{
      reject()
    })
  })
}