import { createStore } from 'vuex'
import storage from 'store'
import request from '@/utils/request'
import { LOGIN_TOKEN, ENTRANCE_ID, CODE_LIST } from '@/store/mutation-types'

export default createStore({
  state: {
		token: '' || storage.get(LOGIN_TOKEN),
    entranceId: '' || storage.get(ENTRANCE_ID),
    codeList: '' || storage.get(CODE_LIST),
  },
  mutations: {
		SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ENTRANCEID: (state, id) => {
      state.entranceId = id
    },
    SET_CODELIST: (state, list) => {
      state.codeList = list
    },
  },
  actions: {
		Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        request({
          url: '/login/pda',
          method: 'POST',
          data: {
            username: userInfo.username,
            password: userInfo.password,
          }
        }).then(res => {
          storage.set(LOGIN_TOKEN, res.tokenValue)
          commit('SET_TOKEN', res.tokenValue)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
      })
    },

    BINDENTRANCE({ commit }, id) {
      return new Promise((resolve, reject) => {
        storage.set(ENTRANCE_ID, id)
        commit('SET_ENTRANCEID', id)
        resolve()
      })
    },

    LOGIN_OUT({ commit }) {
      return new Promise((resolve, reject) => {
        storage.remove(LOGIN_TOKEN)
        commit('SET_TOKEN', '')
        resolve()
      })
    },

    SET_CODE({commit},id) {
      return new Promise((resolve, reject) => {
        storage.set(CODE_LIST, id)
        commit('SET_CODELIST', id)
        resolve()
      })
    },

  },
  modules: {
  }
})
