import { createRouter, createWebHistory } from 'vue-router'
import { Dialog } from 'vant'
import { LOGIN_TOKEN } from '@/store/mutation-types'
import storage from 'store'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    meta: {
      title: '苏尔寿',
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: '登录',
      requireAuth: false
    }
  },
  {
    path: '/enter/personal',
    name: 'personal',
    component: () => import('@/views/enter/personal.vue'),
    meta: {
      title: '个人预约',
      requireAuth: true
    }
  },
  {
    path: '/enter/personalAgain',
    name: 'personalAgain',
    component: () => import('@/views/enter/personalAgain.vue'),
    meta: {
      title: '个人预约',
      requireAuth: true
    }
  },
  {
    path: '/enter/multiple',
    name: 'multiple',
    component: () => import('@/views/enter/multiple.vue'),
    meta: {
      title: '五人以上预约',
      requireAuth: true
    }
  },
  {
    path: '/enter/success',
    name: 'enterSuccess',
    component: () => import('@/views/enter/success.vue'),
    meta: {
      title: '入厂成功',
      requireAuth: true
    }
  },
  {
    path: '/leave/personal',
    name: 'leavePersonal',
    component: () => import('@/views/leave/personal.vue'),
    meta: {
      title: '个人预约离场',
      requireAuth: true
    }
  },
  {
    path: '/leave/success',
    name: 'leaveSuccess',
    component: () => import('@/views/leave/success.vue'),
    meta: {
      title: '离场成功',
      requireAuth: true
    }
  },
  {
    path: '/leave/multiple',
    name: 'leaveMultiple',
    component: () => import('@/views/leave/multiple.vue'),
    meta: {
      title: '离场成功',
      requireAuth: true
    }
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/setting.vue'),
    meta: {
      title: '设置',
      requireAuth: true
    }
  },
  {
    path: '/greenChannel',
    name: 'greenChannel',
    component: () => import('@/views/greenChannel/index.vue'),
    meta: {
      title: '绿色通道',
      requireAuth: true
    }
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: () => import('@/views/subscribe/index.vue'),
    meta: {
      title: '过磅重量',
      requireAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // 判断是否需要登录
  if (to.meta.requireAuth) {
    let token = storage.get(LOGIN_TOKEN);
    if (token) {
      next()
    } else {
      Dialog.confirm({
        message: '您还未登录,请先登录',
        showCancelButton: false,
      }) .then(() => {
        router.replace("/login")
      })
    }
  } else {
    next()
  }
})

export default router
