<template>
  <router-view />
</template>

<script>
export default {
  components: {
  },
}
</script>
<style>
  /* 公共样式 */
  @import '~@/assets/css/common.css';
  /* 字体 */
  @import '~@/assets/css/iconfont.css';
</style>
